<template>
  <div>
    <portal to='modal-header'>
      <div class='flex flex-row'>
        <h1 class='px-8 py-8 text-2xl font-semibold tracking-wide'>{{selectedIpo.name}} 참여펀드 리스트</h1>
        <v-select
          v-model='selectedTargetIpo'
          label='name'
          class='px-8 py-8 ml-56 w-64 text-sm'
          :close-on-select='true'
          :options='ipos'
          placeholder='IPO 종목 선택'>
          <template v-slot:option='option'>
            <span>{{ option.name }}</span>
          </template>
          <template v-slot:no-options>
            <div class='py-3 text-gray-500 text-center'>IPO 종목</div>
          </template>
        </v-select>
        <button class='border px-2 py-2 mt-8 mb-8 text-white bg-gray-800 rounded-md font-bold'
                :disabled='!hasSelectedTargetIpo' @click='submitCopyFundLists'>참여펀드복사</button>
      </div>
    </portal>
    <div class='flex justify-start'>
      <section class=''>
        <div class='w-48 text-sm uppercase font-semibold text-red-600 py-2 pl-2' style='font-size: 20px;'>참여펀드</div>
        <table class='table-fixed w-full text-sm'>
          <thead class='whitespace-no-wrap'>
            <tr>
              <th class='pr-2 py-2 w-24'>
                <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
                  <input type='checkbox'
                    class='form-checkbox'
                    v-model='participationAll'
                    @change='changeParticipation'>
                  <span>선택</span>
                </label>
              </th>
              <th class='pr-2 py-2 w-32'>펀드코드</th>
              <th class='px-2 py-2 w-32'>펀드명</th>
              <th class='px-2 py-2 w-32'>계좌구분</th>
            </tr>
          </thead>
          <template v-for='(accountTypeData, accountType) in groupByAccountType'>
            <tbody v-for='(funds, index) in accountTypeData' :key='`${accountType}-${index}`'>
              <tr class='font-bold'>
                <td class='pr-2 py-1'>{{accountType}}</td>
                <td class='pr-2 py-1'>{{funds.length}} 계좌 참여</td>
                <td class='pr-2 py-1'></td>
                <td class='pr-2 py-1'></td>
              </tr>
              <tr v-for='fund in funds' :key='`${fund.fund_code}`'>
                <td class='pr-2 py-1 w-24'>
                  <input type='checkbox'
                    class='form-checkbox'
                    v-model='fund.is_exist'>
                </td>
                <td class='pr-2 py-1 w-32'>{{fund.fund_code}}</td>
                <td class='pr-2 py-1 w-32'>{{fund.short_name}}</td>
                <td class='pr-2 py-1 w-32'>
                  <select v-model='fund.account_type' class='form-select'>
                    <option v-for='accountType in accountTypes'
                            :key='accountType'
                            :value='accountType'>
                      {{accountType}}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </template>
        </table>
      </section>
      <section class=''>
        <div class='w-48 text-sm uppercase font-semibold text-red-600 py-2 pl-2' style='font-size: 20px;'>미참여펀드</div>
        <table class='table-fixed w-full text-sm'>
          <thead>
            <tr>
              <th class='pr-2 py-2 w-24'>
                <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
                  <input type='checkbox'
                    class='form-checkbox pl-4'
                    v-model='nonParticipationAll'
                    @change='changeNonParticipation'>
                  <span>선택</span>
                </label>
              </th>
              <th class='pr-2 py-2 w-32'>펀드코드</th>
              <th class='px-2 py-2 w-32'>펀드명</th>
              <th class='px-2 py-2 w-32'>계좌구분</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='fund in nonParticipationFundLists'
              :key='`${fund.id}-${fund.short_name}`'>
              <td class='pr-2 py-2 w-24'>
                <input type='checkbox'
                  class='form-checkbox'
                  v-model='fund.is_exist'>
              </td>
              <td class='pr-2 py-2 w-32'>{{fund.fund_code}}</td>
              <td class='pr-2 py-2 w-32'>{{fund.short_name}}</td>
              <td class='pr-2 py-2 w-32'>{{fund.account_type}}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <portal to='modal-footer'>
      <div class='m-4'>
        <button class='border px-12 h-12 text-white bg-gray-900 rounded-md font-bold'
                @click='submitFundLists'>저장</button>
      </div>
    </portal>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'

export default {
  name: 'IpoFundLists',
  data () {
    return {
      participationAll: false,
      nonParticipationAll: false,
      selectedTargetIpo: null
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipos',
      'accountTypes',
      'selectedIpo',
    ]),
    ...mapState('funds', {
      stateIpoFundLists: 'ipoFundLists',
    }),
    ...mapMultiRowFields('funds', [
      'ipoFundLists',
    ]),
    participationFundLists () {
      return this.ipoFundLists.filter(fund => fund.is_exist === true)
    },
    nonParticipationFundLists () {
      return this.ipoFundLists.filter(fund => fund.is_exist === false)
    },
    groupByAccountType () {
      return this.participationFundLists.reduce((hsh, row) => {
        hsh[row.account_type] ||= {}
        hsh[row.account_type][row.account_type] ||= []
        hsh[row.account_type][row.account_type].push(row)

        return hsh
      }, {})
    },
    hasSelectedTargetIpo () {
      return this.selectedTargetIpo ? Object.keys(this.selectedTargetIpo).length > 0 : false
    },
  },
  methods: {
    ...mapActions(['modalClose']),
    ...mapActions('funds', [
      'getIpoFundLists',
      'updateIpoFundLists',
      'copyIpoFundLists',
    ]),
    refreshData () {
      this.getIpoFundLists(this.selectedIpo.id)
    },
    submitFundLists () {
      this.updateIpoFundLists(this.selectedIpo.id).then(() => {
        this.$message({
          type: 'success',
          message: `등록 완료`
        })
        this.modalClose()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '등록 실패'
        })
      })
    },
    submitCopyFundLists () {
      this.copyIpoFundLists({ipoId: this.selectedIpo.id, targetIpoId: this.selectedTargetIpo.id}).then(() => {
        this.$message({
          type: 'success',
          message: `복사 완료`
        })
        this.modalClose()
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: '복사 실패'
        })
      })
    },
    changeParticipation () {
      this.nonParticipationAll = !this.participationAll
      this.participationFundLists.map(fund => fund.is_exist = !fund.is_exist)
    },
    changeNonParticipation () {
      this.participationAll = !this.nonParticipationAll
      this.nonParticipationFundLists.map(fund => fund.is_exist = !fund.is_exist)
    },
  },
  mounted () {
    this.refreshData()
  }
}
</script>
